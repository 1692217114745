<template>
  <footer>
    <a href="https://www.paytel.pl/" title="paytel">
      <logo-component class="logo" :light="true"/>
    </a>
  </footer>
</template>

<script>
import LogoComponent from "@/components/LogoComponent";
export default {
  name: 'FooterComponent',
  components: {LogoComponent},
}
</script>

<style scoped lang="scss">
  footer{
    padding: 48px 30px;
    text-align: center;
    background: #0D1B33;
    position: relative;
    .logo {
      width: 100%;
      max-width: 189px;
      position: relative;
      z-index: 12;
      margin-right: 100px;
    }
  }
</style>
