import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);
import VueMask from 'v-mask'
Vue.use(VueMask);
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: { id: "GTM-M9X7VSQ" }
}, router)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
