<template>
  <div :class="['header']">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-row justify-content-between justify-content-xl-start align-items-center">
            <div class="logo mr-5">
              <a href="/">
                <logo-component class="w-100"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LogoComponent from "@/components/LogoComponent";
export default {
  name: "NavigationComponent",
  components: {LogoComponent},
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  padding: 30px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  .logo {
    width: 100%;
    max-width: 189px;
    position: relative;
    z-index: 12;
    margin-right: 100px;
  }
}
</style>
